import React from 'react';
import useScript from '../../hooks/useScript';
import { Root, Container, StampImg, Title, Body } from './CookiePolicy.style';

import StampSrc from '../../images/stamp-beige.svg';

const Terms = () => {
  useScript('https://app.termly.io/embed-policy.min.js');

  return (
    <Root>
      <Container>
        <StampImg src={StampSrc} alt="" />

        <Body>
          <div name="termly-embed" data-id="be2d704f-4768-4c00-9f6e-3c87f0d2feee" data-type="iframe"></div> 
        </Body>
      </Container>
    </Root>
  );
};

export default Terms;
