import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e9dfd0;
  overflow: hidden;

  ${'' /* @media (max-width: 1023px) {
    padding: 40px 0;
  }

  @media (min-width: 1024px) {
    padding: 100px 0;
  } */}
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1064px;
  padding: 0 24px;
  color: #141f35;

  p {
    margin-top: 16px;
  }

  p + p {
    margin-top: 32px;
  }
`;

export const StampImg = styled.img`
  position: absolute;

  @media (max-width: 1023px) {
    top: 13%;
    left: -5px;
    width: 136px;
  }

  @media (min-width: 1024px) {
    top: 300px;
    left: -110px;
  }
`;

export const Title = styled.h4`
  margin: 0;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 32px;
    text-align: center;
  }

  @media (min-width: 640px) {
    font-size: 48px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const Body = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    max-width: 740px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }
`;
