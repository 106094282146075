import React from "react";

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import CookiePolicy from '../components/CookiePolicy/CookiePolicy';
import Footer from '../components/Footer/Footer';

import BgDesktopSrc from '../images/hero-fire-bg-desktop.jpg';
import BgMobileSrc from '../images/hero-fire-bg-mobile.jpg';

const TermsPage = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header />
      <Hero
        img={{ mobile: BgMobileSrc, desktop: BgDesktopSrc }}
        title="cookie&nbsp;&amp;&nbsp;policy"
      />
      <CookiePolicy />
      <Footer />
    </div>
  );
};

export default TermsPage;
